import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '@/components/Layout';
import PortfolioHeader from '@/components/Portfolio/PortfolioHeader';
import SoloPicture from '@/components/Portfolio/SoloPicture';
import ContentSection from '@/components/Portfolio/ContentSection';
import DoublePictures from '@/components/Portfolio/DoublePictures';
import NextProject from '@/components/Portfolio/NextProject';

import { ARTELINK_PROJECT } from '@/referentials/routes';

export default function LaPoste({ data }) {
  const { t } = useTranslation();

  const tags = ['development', 'ux', 'intrapreunariat', 'print'];

  return (
    <Layout description={t('description')}>
      <PortfolioHeader
        title={t('title')}
        websites={[t('website')]}
        description={t('description')}
        tags={tags}
      />
      <SoloPicture source={data.cover} alt={t('laposteOutil')} />

      <ContentSection title={t('paragraphTitle')} content={t('paragraph1')} />

      <DoublePictures
        pictures={[
          {
            source: data.icono,
            alt: 'Icono La Poste',
          },
          {
            source: data.compo,
            alt: 'Compo laposte',
          },
        ]}
      />

      <ContentSection title={t('listFeatures')} content={t('features')} />

      <DoublePictures
        pictures={[
          {
            source: data.ipad,
            alt: 'iPad responsive',
          },
          {
            source: data.mobile,
            alt: 'Mobile responsive',
          },
        ]}
      />
      <NextProject title={t('nextProject')} to={ARTELINK_PROJECT} />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["laposte", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    cover: file(relativePath: { eq: "projects/la-poste/laposte.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    compo: file(relativePath: { eq: "projects/la-poste/compo.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    icono: file(relativePath: { eq: "projects/la-poste/icono.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    ipad: file(relativePath: { eq: "projects/la-poste/ipad.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    mobile: file(relativePath: { eq: "projects/la-poste/mobile.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
